.logo {
  background-color: rgba(0,0,0,0);
  opacity: 1;
  transition: opacity 2s linear;
}

.logo img {
  cursor: pointer;
  border: 1px solid var(--logo-border);
  filter: none;
  transition: 1s transform linear, 1s filter linear, 1s border linear;
}

.logo img:hover {
  border: 3px solid var(--logo-border-hover);
  filter: invert(0.2) hue-rotate(360deg);
  transform: scale(1.01);
}

.logo img.grey {
  transition: none;
  transform: scale(1.15);
  border: 3px solid #000000;
  filter: grayscale(100%) invert(1) contrast(3);
}
