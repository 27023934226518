:root {
  --bg-color: #808080;
  --menu-color: white;
  --menu-color-active: goldenrod;
  --menu-color-seperator: yellowgreen;
  --logo-border: green;
  --logo-border-hover: white;
}

.app {
  height: 100vh;
  width: 100vw;
}

.centerScreen {
  left: 50%;
  margin-right: -50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) 
}

.menu button {
  background: none;
  color: var(--menu-color);
  cursor: pointer;
  transition: transform 250ms ease-in-out, font-weight 250ms ease-in-out;
}

.menu button:hover {
  transform: scale(1, 1.4);
}

.menu button.active {
  color: var(--menu-color-active);
}

.menu ul li {
  display: inline;
}

.menu .seperator {
  cursor: none;
  padding: 0 10px;
  color: var(--menu-color-seperator);
}

.humanValidation {
  font-weight: bold;
  transition-property: filter;
  transition: 1s linear;
}

.humanValidation:hover {
  filter: blur(2px);
}